import React, {
    useState,
    useEffect
} from "react";
import {
    Accordion,
    Icon,
    Loader
} from "semantic-ui-react";
import {
    useEstudios
} from "../../../hooks";
import {
    map
} from "lodash";
import {
    EspecialidadDetalle
} from "./EspecialidadDetalle";

const EspecialidadColegiado = (props) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const {
        allEstudios,
        getAllTipoEstudios
    } =
    useEstudios();
    const {
        ncop
    } = props;

    useEffect(() => {
        getAllTipoEstudios(ncop);
    }, []);

    useEffect(() => {
        if (allEstudios?.length === 1) {
            setActiveIndex(0);
        }
    }, [allEstudios]);


    const handleClick = (e, titleProps) => {
        const {
            index
        } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        setActiveIndex(newIndex);
    };

    if (allEstudios === null) {
        return null;
    }    
    return ( <> {
            map(allEstudios, (tipoEstudios, index) => ( 
                <Accordion key={index} styled >
                    <Accordion.Title active = {
                            activeIndex === index
                        }
                        index = {
                            index
                        }
                        onClick = {
                            handleClick
                        } >
                        <Icon name = "dropdown" /> {
                            tipoEstudios.name
                        } 
                    </Accordion.Title>
                    <Accordion.Content active = {
                            activeIndex === index
                        } >
                        <EspecialidadDetalle key = {
                            index
                        }
                        estudios = { 
                            tipoEstudios.estudios
                        }
                    /> 
                    </Accordion.Content>
                </Accordion>
            ))
        } </>
    );
};

export default EspecialidadColegiado;