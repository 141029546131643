import React, {
    useEffect
} from "react";
import {
    Tab
} from "semantic-ui-react";
import {
    useEstudios
} from "../../../hooks";
import {
    map
} from "lodash";
import DetalleEspecialidad from "./DetalleEspecialidad";

const Especilidad = (props) => {
    const {
        allEstudios,
        getAllTipoEstudios
    } = useEstudios();
    const {
        datosColegiado
    } = props;

    useEffect(() => {
        getAllTipoEstudios(datosColegiado.results[0].cop);
    }, []);

    if (allEstudios === null) {
        return null;
    }

    const detalle = (TipoEstudios, datosColegiado) => {
        return map(TipoEstudios, (tipoEstudio, index) => ({
            key: tipoEstudio.idtipo_estudio,
            menuItem: tipoEstudio.name,
            render: () => ( <DetalleEspecialidad key = {
                    index
                }
                estudios = {
                    tipoEstudio.estudios
                }
                />
            ),
        }));
    };

    return ( <Tab style = {
            {
                marginTop: "30px"
            }
        }
        menu = {
            {
                fluid: true,
                vertical: true,
                tabular: true
            }
        }
        panes = {
            detalle(allEstudios, datosColegiado)
        }
        />
    );
};
export default Especilidad;