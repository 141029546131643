export const BASE_API = "https://apicopperu.colegiodeobstetras.pe";
export const DOMAIN_WEB = "https://conoce.colegiodeobstetras.pe";
//export const BASE_API = "http://127.0.0.1:8000";
export const TOKEN = "token.colegio.obstetras";


export const ORDER_STATUS = {
    PENDING: "PENDING",
    DELIVERED: "DELIVERED",
}

export const PAYMENT_STATUS = {
    PENDING: "PENDING",
    PAID: "PAID"
}