import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import {
    map
} from "lodash";
import routes from ".";

console.log(routes);

export function Navigation() {
    return ( <Router >
        <Routes> {
            map(routes, (route, index) => {
                return ( <Route key = {
                    index
                }
                path = {
                    route.path
                }
                exact = {
                    route.exact
                }                
                element = { 
                    <route.layout >
                        <route.component />
                    </route.layout>
                }
                />
            )})
        } </Routes>

        </Router>
    );
}