import {
    useState
} from "react";
import {
    size
} from "lodash";
import {
    getEstudiosByCopApi,
    getTipoEstudiosApi
} from "../api/estudios";
import {
    useAuth
} from "./";

export function useEstudios() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [estudios, setEstudios] = useState(null);
    const [tipoEstudios, setTiposEstudios] = useState(null);
    const [allEstudios, setAllEstudios] = useState(null);

    const {
        auth
    } = useAuth();

    const getEstudiosByCop = async (ncop, ntipoestudio) => {
        try {
            setLoading(true);
            const response = await getEstudiosByCopApi(ncop, ntipoestudio);
            console.log("Response", response);
            setLoading(false);
            setEstudios(response);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    const getTipoEstudios = async () => {
        try {
            setLoading(true);
            const response = await getTipoEstudiosApi();
            setLoading(false);
            setTiposEstudios(response);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    const getAllTipoEstudios = async (ncop) => {
        try {
            setLoading(true);
            const response = await getTipoEstudiosApi();
            const result = await Promise.all(response.map(async (tipoEstudios) => {
                const obj = { ...tipoEstudios };
                obj.estudios = await getEstudiosByCopApi(ncop, tipoEstudios.idtipo_estudio);
                return obj;
            }));
            const filteredResult = result.filter(e => e.estudios.length > 0);
            setLoading(false);
            setAllEstudios(filteredResult);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    return {
        loading,
        error,
        estudios,
        getEstudiosByCop,
        tipoEstudios,
        getTipoEstudios,
        allEstudios,
        getAllTipoEstudios
    };
}